import axios from 'axios';
import env from "react-dotenv";

const URL_BASE = process.env.REACT_APP_BASE_URL;

const headers = {
    'x-functions-key': process.env.REACT_APP_X_FUNCTIONS_KEY
}

export async function registrarComprobante(payload) {
    let data = [];
    await axios.post(URL_BASE + "comprobante/registro", payload, {
        headers: headers
    }).then(r => {
        console.log(data);
        data = r.data
    });
    return data;
}

export async function obtenerProveedor(rucProveedor) {

    let data = [];
    await axios.get(URL_BASE + "proveedor/consulta/" + rucProveedor, {
        headers: headers
    }).then(r => {
        data = r.data
    })
    return data;
}

export async function validacionXML(payload) {
    let data = [];
    await axios.post(URL_BASE + "comprobante/validacionXML", payload , {
        headers: headers
    }).then(r => {        
        data = r.data
        console.log(data);
    });
    return data;
}

export async function consultarConformidades(payload) {
    let data = [];
    await axios.post(URL_BASE + "comprobante/consultarConformidades", payload , {
        headers: headers
    }).then(r => {        
        data = r.data
        console.log(data);
    });
    return data;
}