import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Loading.css';

const Loading = ({ file }) => {

    return (
        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
    )
}
export default Loading;