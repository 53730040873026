import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Comprobante from './views/Comprobante';
import Prohibido from './views/Prohibido';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        {/* <Route exact path='/' component={Prohibido} />
        <Route exact path='/registro' component={Comprobante} /> */}
        <Route exact path='/' component={Comprobante} />
      </Switch>
    </Router>
  );
}

export default App;