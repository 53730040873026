import React, { useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from '../logo.png';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faSave } from '@fortawesome/free-solid-svg-icons';
import {
  registrarComprobante as apiRegistrarComprobante,
  obtenerProveedor as apiObtenerProveedor,
  validacionXML as apiValidacionXML,
  consultarConformidades as apiConsultarConformidades
} from "../api/api";
import { useHistory } from 'react-router-dom';
import Loading from "../components/Loading";
import "../components/Loading.css";
import { formatoFechaYMD } from "../utils/fechas";
import Alert from 'react-bootstrap/Alert'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer';
import Finalizado from "./Finalizado";
import { useForm } from 'react-hook-form';
import env from "react-dotenv";
import Modal from 'react-bootstrap/Modal';
import InputMask from 'react-input-mask';
import axios from 'axios';
import saveAs from 'file-saver';
import { parseString } from 'xml2js';

function validateFloatKeyPress(el) {
  let v = el.target.value;
  let numero = v.split(".");
  if (numero.length == 1) {
    if (numero[0].length > 10) {
      el.target.value = v.substring(0, 10);
    }
  }

  if (numero.length == 2) {
    if (numero[0].length > 10) {
      el.target.value = v.substring(0, 10);
    }

    if (numero[1].length > 2) {
      if (numero[1].length == 2) {
        el.target.value = v.substring(0, v.length - 1);
      } else {
        numero[1] = numero[1].substring(0, 2);
        el.target.value = numero[0] + "." + numero[1];
      }
    }
  }
}

function agregarTrim(el) {
  let v = el.target.value;
  el.target.value = v.trim();
  if(el.target.id!=null){
    if(el.target.id=="CorreoContacto"){
      document.getElementById("CorreoContacto").setAttribute('value', v.trim());
    }
  }
}



function Comprobante(props) {
  const [cargando, setCargando] = useState(true);
  const [finalizado, setFinalizado] = useState(false);
  const history = useHistory();
  const [showMessageError, setShowMessageError] = useState(false);
  const [messageError, setMessageError] = useState(null);
  const [validated, setValidated] = useState(false);
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [correoContacto, setCorreoContacto] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [idProveedor, setIdProveedor] = useState(null);
  const [razonSocialDisabled, setRazonSocialDisabled] = useState(true);
  const [correoContactoDisabled, setCorreoContactoDisabled] = useState(true);
  const MAX_SIZE_XML = process.env.REACT_APP_MAX_SIZE_XML;
  const MAX_SIZE_PDF = process.env.REACT_APP_MAX_SIZE_PDF;
  const MAX_SIZE_SUSTENTOS = process.env.REACT_APP_MAX_SIZE_SUSTENTOS;
  const [priceValueInput, setPriceValueInput] = useState(null);
  const [file, setFile] = useState(null);
  const [rucProveedor, setRucProveedor] = useState('');
  const [tipoDocumento, setTipoDocumento] = useState('');
  const [codigoEmpresaOrigen, setCodigoEmpresaOrigen] = useState('');
  const [serie, setSerie] = useState('');
  const [numero, setNumero] = useState('');
  const [tipoMoneda, setTipoMoneda] = useState('');
  const [importeTotal, setImporteTotal] = useState('');
  const [fechaEmision, setFechaEmision] = useState('');
  const [OpcionesConformidades, setOpcionesConformidades] = useState([]);
  const [OpcionesConformidadesCompleto, setOpcionesConformidadesCompleto] = useState([]);
  const [listaConformidades, setListaConformidades] = useState([]);
  const [nroOrdenCompra, setNroOrdenCompra] = useState('');
  const [defaultListConformidades, setdefaultListConf] = useState([]);
  const [idEstado, setIdEstado] = useState('2');
  const [importeIGV, setImporteIGV] = useState('');
  const [importeSubTotal, setImporteSubTotal] = useState('');

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  }

  const handleFileRead = async (file) => {
    setCargando(true);
    let data = new FormData();
    data.append('ArchivoXml', file);
    apiValidacionXML(data).then(comprobante => {
      setRucProveedor(comprobante.rucProveedor);
      setRazonSocial(comprobante.razonSocial);
      setTipoDocumento(comprobante.tipoDocumento);
      setCorreoContacto(comprobante.correoContacto);
      setCodigoEmpresaOrigen(comprobante.codigoEmpresaOrigen);
      setSerie(comprobante.serieDocumento);
      setNumero(comprobante.nroDocumento);
      setTipoMoneda(comprobante.codigoTipoMoneda);
      setImporteTotal(comprobante.importeTotal);
      setFechaEmision(comprobante.strFechaEmision);
      setIdProveedor(comprobante.idProveedor);
      setNroOrdenCompra(comprobante.nroOrdenCompra == null ? '' : comprobante.nroOrdenCompra);         
      setImporteIGV(comprobante.importeIGV);
      setImporteSubTotal(comprobante.importeSubTotal);
      console.log(comprobante);
      ConsultarConformidades(comprobante.listaDetalle, comprobante.nroOrdenCompra,comprobante.importeTotal );
    }).catch(error => {
      setCargando(false); 
      console.log(error);
      if (error.response != undefined){
        mostrarAlertaError(error.response.data.errorDescription)
      } else {
        mostrarAlertaError(error)
      }      
    });
  };

  const ConsultarAprobaciones = async (e) => {
    if (e.key === 'Enter') {
      ConsultarConformidades(null,e.target.value, importeTotal);
    }    
  };

  function ConsultarConformidades(lista = null, ordenCompra = null, importePrueba = null){
    if (cargando == false){
      setCargando(true);
    }    
    const data = {
      OrdenCompra: ordenCompra,
      ImportePrueba: importePrueba
    };
    if (ordenCompra != null){
      apiConsultarConformidades(data).then(object => {
        setCargando(false);      
        let list = [];
        let listImporte = [];
        if (lista != null){
          lista.map((item) => {          
            list.push(item.numeroConformidad);
            listImporte.push(item.importe);
          })
          setListaConformidades(list);
        }
        setCargando(false);
        setOpcionesConformidades([]);
        setOpcionesConformidadesCompleto([]);
        setOpcionesConformidadesCompleto(object.listaConformidades);

        // Agrupar por categoría
        let conformidades = object.listaConformidades.reduce((resultado, elemento) => {
          // Verificar si el grupo ya existe
          if (!resultado[elemento.numeroConformidad]) {
            resultado[elemento.numeroConformidad] = {
              numeroConformidad : elemento.numeroConformidad,
              importe : 0
            }; // Crear el grupo si no existe
          }
          
          // Agregar el elemento al grupo correspondiente
          resultado[elemento.numeroConformidad].importe += elemento.importe;
          
          return resultado;
        }, {});

        setOpcionesConformidades(Object.values(conformidades));              
      }).catch(error => {
        setCargando(false);
        setOpcionesConformidades([]);
        setOpcionesConformidadesCompleto([]);
        mostrarAlertaError("Error al obtener los numeros de conformidad.") 
      });      
    } else {
      setCargando(false);
    }    
  }

  const handleSelectChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    
    const selectedValues = selectedOptions.map((option) => option.value);
    const selectedValuesImporte = selectedOptions.map((option) => option.dataset.importe);
    setListaConformidades(selectedValues);
  }

  const handleOptionMouseDown = (event) => {
    event.preventDefault();

    const optionValue = event.target.value;
    const isSelected = listaConformidades.includes(optionValue);

    if (isSelected) {
      const updatedOptions = listaConformidades.filter((value) => value !== optionValue);
      setListaConformidades(updatedOptions);
    } else {
      const updatedOptions = [...listaConformidades, optionValue];
      setListaConformidades(updatedOptions);
    }
  };

  const mostrarAlertaError = (mensaje) => {
    setMessageError(mensaje);
    setShowMessageError(true);
  }
  const mostrarAlertaExito = (mensaje) => {
    setMessageSuccess(mensaje);
    setShowMessageSuccess(true);
  }

  const handleClose = () => setShowMessageError(false);
  const validarFormato = (event) => {
    let validado = false;
    let control = event.target;
    let file = control.files[0];
    let extension = file.type;
    let peso = (file.size) / 1024;
    let nombre = file.name;    

    switch (control.name) {
      case "UrlArchivoXml":
        if (extension != "text/xml") {
          validado = true;
          control.value = null;
          mostrarAlertaError("Formato incorrecto");
        }

        if (peso > MAX_SIZE_XML) {
          control.value = null;
          mostrarAlertaError("El peso máximo es: " + MAX_SIZE_XML / 1024 + " MB");
        }
        limpiarFormulario();
        setFile(file);
        break;

      case "UrlArchivoPdf":
        if (extension != "application/pdf") {
          control.value = null;
          validado = true;
          mostrarAlertaError("Formato incorrecto");
        }

        if (peso > MAX_SIZE_PDF) {
          control.value = null;
          mostrarAlertaError("El peso máximo es: " + MAX_SIZE_PDF / 1024 + " MB");
        }
        break;

      case "UrlArchivoSustentos":
        console.log(extension);
        if (!(["application/zip", "application/x-zip-compressed", "application/x-rar-compressed", "application/x-7z-compressed"].includes(extension))) {
          if (extension == ""){
            let extesion_aux = "";
            try {
              extesion_aux = nombre.substring(nombre.lastIndexOf(".") + 1).toLocaleUpperCase();
            } catch (error) {
              extesion_aux = "";
            } 
            if (!(["ZIP","RAR","7Z"].includes(extesion_aux))){
              control.value = null;
              validado = true;
              mostrarAlertaError("Formato incorrecto");
            }
          } else {
            control.value = null;
            validado = true;
            mostrarAlertaError("Formato incorrecto");
          }          
        } 

        if (peso > MAX_SIZE_SUSTENTOS) {
          control.value = null;
          mostrarAlertaError("El peso máximo es: " + MAX_SIZE_SUSTENTOS / 1024 + " MB");
        }
        break;
    }
    //var file = new File(["Hello, world!"], "hello world.txt", {type: "text/plain;charset=utf-8"});
    //var FileSaver = require('file-saver');
    //FileSaver.saveAs(file);
    //readXmlData(file);
  }

  const limpiarFormulario = (full = false) => {
    if (full) document.getElementById('frmComprobante').reset();    
    setCodigoEmpresaOrigen("");
    setTipoDocumento("");
    setFechaEmision("");
    setTipoMoneda("");
    setImporteTotal("");
    setNroOrdenCompra("");
    setOpcionesConformidades([]);
    setOpcionesConformidadesCompleto([]);
    setListaConformidades([]);
    setRazonSocial("");
    setCorreoContacto("");
    setRucProveedor("");
    setSerie("");
    setNumero("");
    setIdEstado("2");
    setValidated(false);
  }

  const obtenerProveedor = async (event) => {
    let rucProveedor = event.target.value
    if (rucProveedor.length == 11) {
      apiObtenerProveedor(rucProveedor).then(proveedor => {
        setCorreoContacto(proveedor.correoContacto);
        setRazonSocial(proveedor.razonSocial);
        setRazonSocialDisabled(true);
        setCorreoContactoDisabled(true);
        setIdProveedor(proveedor.idProveedor);
      }).catch(error => {
        setCorreoContacto("");
        setRazonSocial("");
        setRazonSocialDisabled(false);
        setCorreoContactoDisabled(false);
        //mostrarAlertaError("No registrado");
      })
    }
  }

  const inputChange = (evt) => {
    let obj = {}
    let value = evt.target.value
    let type = document.getElementById(evt.target.id).type
    if (type == 'number') {
      value = (value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
    }
    obj[evt.target.id] = value
    setPriceValueInput(value);
  }

  const onSubmit = async (e) => {
    setCargando(true);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    e.preventDefault();

    const formData = new FormData(e.target), formDataObj = Object.fromEntries(formData.entries());
    let formData2 = new FormData();
    formData2.append('CodigoTipoDocumento', formDataObj.CodigoTipoDocumento.trim());
    formData2.append('IdProveedor', idProveedor);
    formData2.append('RucProveedor', formDataObj.RucProveedor.trim());
    formData2.append('CorreoContacto', formDataObj.CorreoContacto.trim());
    formData2.append('RazonSocial', formDataObj.RazonSocial.trim());
    formData2.append('SerieDocumento', formDataObj.SerieDocumento.trim());
    formData2.append('NroDocumento', formDataObj.NumeroDocumento.trim());
    formData2.append('FechaEmision', formDataObj.FechaEmision.trim());
    formData2.append('NroOrdenCompra', formDataObj.NumeroOrdenCompra.trim());
    formData2.append('Conformidades', listaConformidades);
    //formData2.append('PosicionOrdenCompra', formDataObj.PosicionOrdenCompra.trim());
    formData2.append('ImporteTotal', formDataObj.ImporteTotal.trim());
    formData2.append('UrlArchivoXml', formDataObj.UrlArchivoXml);
    formData2.append('UrlArchivoPdf', formDataObj.UrlArchivoPdf);
    formData2.append('UrlArchivoSustentos', formDataObj.UrlArchivoSustentos);
    formData2.append('CodigoTipoMoneda', formDataObj.CodigoTipoMoneda.trim());
    formData2.append('CodigoEmpresaOrigen', formDataObj.CodigoEmpresaOrigen.trim());
    formData2.append('OpcionesConformidades', JSON.stringify(OpcionesConformidadesCompleto.filter((obj) => listaConformidades.includes(obj.numeroConformidad))));
    formData2.append('IdEstado', idEstado);
    formData2.append('ImporteIGV', importeIGV);    
    formData2.append('ImporteSubTotal', importeSubTotal);

    apiRegistrarComprobante(formData2).then(r => {     
      setCargando(false); 
      if (idEstado == "1"){
        mostrarAlertaExito("Su comprobante fue registrado en estado pendiente.");  
      } else {
        mostrarAlertaExito("Su comprobante fue recibido y está en proceso de validación");
      }
      limpiarFormulario(true);
      /*setCorreoContacto("");
      setRazonSocial("");
      setRazonSocialDisabled(false);
      setCorreoContactoDisabled(false);*/
      e.target.reset();
      setValidated(false);
    }).catch(error => {
      setCargando(false); 
      console.log(error);
      if (error.response != undefined){
        mostrarAlertaError(error.response.data.errorDescription)
      } else {
        mostrarAlertaError(error)
      }      
    });
  }

  useEffect(() => {
    setCargando(false);
    if (file) {
      handleFileRead(file);
    }
  }, [file])

  /*if (cargando == true) {
    return <Loading />
  } else*/ if (finalizado == true) {
    return <Finalizado logo={logo} />
  } else {
    return (
      <div>      
      {cargando && <Loading />}
      <Container className='parent-container'>
        <Row className="p-3 justify-content-md-center">
          <Col lg="12">
            <Card>
              <Card.Header>
                <span><b>Registro de Comprobante</b></span>
                <Button onClick={() => limpiarFormulario(true)} className='float-end btn-sm' variant="danger">
                  <FontAwesomeIcon icon={faEraser} /> Limpiar
                </Button>
              </Card.Header>
              <Card.Body>
                <Modal
                  show={showMessageError}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Advertencia</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='text-center py-5'>
                    {messageError}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowMessageError(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={showMessageSuccess}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Éxito</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='text-center py-5'>
                    {messageSuccess}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowMessageSuccess(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Form id="frmComprobante" noValidate validated={validated} onSubmit={onSubmit}>
                  <Row className="mt-3">
                    <Col lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Adjuntar XML</Form.Label>
                        <Form.Control onChange={validarFormato} accept='.xml' name="UrlArchivoXml" required={true} className="inputMaterial"
                          type="file" />
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Adjuntar PDF</Form.Label>
                        <Form.Control onChange={validarFormato} accept='.pdf' name="UrlArchivoPdf" required={true} className="inputMaterial"
                          type="file" />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>Serie</Form.Label>
                        <Form.Control onChange={agregarTrim} name="SerieDocumento" autoComplete='off' maxLength={5} placeholder={"Serie"} required={true} className="inputMaterial" value={serie}
                          type="text" />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>Número</Form.Label>
                        <Form.Control onChange={agregarTrim} onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} name="NumeroDocumento" maxLength={8} autoComplete='off' placeholder={"Número"} required={true} className="inputMaterial" value={numero}
                          type="text" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                  <h6>Datos de Factura</h6>
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Ruc</Form.Label>
                        <Form.Control onChange={agregarTrim} onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} minLength={8} maxLength={11} autoComplete='off' name="RucProveedor" readOnly={true} defaultValue={rucProveedor} onKeyUp={obtenerProveedor} id="rucProveedor" placeholder={"Ruc"} required={true} className="inputMaterial"
                          type="text" />
                      </Form.Group>
                    </Col>
                    <Col lg={5}>
                      <Form.Group className="mb-3">
                        <Form.Label>Razón Social</Form.Label>
                        <Form.Control onChange={agregarTrim} maxLength={200} autoComplete='off' name="RazonSocial" readOnly={razonSocialDisabled} defaultValue={razonSocial} placeholder={"Razon social"} required={true} className="inputMaterial"
                          type="text" />
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Correo</Form.Label>
                        <Form.Control id="CorreoContacto" onChange={agregarTrim} maxLength={100} autoComplete='off' name="CorreoContacto" defaultValue={correoContacto} placeholder={"Correo"} required={true} className="inputMaterial"
                          type="email" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Empresa de Origen</Form.Label>
                        <Form.Select required={true} minLength="2" name="CodigoEmpresaOrigen" disabled={true} value={codigoEmpresaOrigen} onChange={(event) => setCodigoEmpresaOrigen(event.target.value)}>
                          <option value="">Seleccione</option>
                          <option value="800">DINET SA</option>
                          <option value="600">DT DINET SA</option>
                          <option value="400">INMOBILIARIA FENIX SAC</option>
                        </Form.Select>
                        <input type="hidden" name='CodigoEmpresaOrigen' value={codigoEmpresaOrigen}></input>
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Tipo de Documento</Form.Label>
                        <Form.Select required={true} minLength="2" name="CodigoTipoDocumento" disabled={true} value={tipoDocumento} onChange={(event) => setTipoDocumento(event.target.value)}>
                          <option value="">Seleccione</option>
                          <option value="01">Factura</option>
                          <option value="02">Recibo por Honorarios</option>
                          <option value="03">Boleta</option>
                          <option value="07">Nota de Crédito</option>
                          <option value="08">Nota de Débito</option>
                          <option value="09">Guia</option>
                          <option value="00">Otros(Ticket)</option>
                        </Form.Select>
                        <input type="hidden" name='CodigoTipoDocumento' value={tipoDocumento}></input>
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Fecha de Emisión</Form.Label>
                        <Form.Control name="FechaEmision" placeholder={"Fecha de Emisión"} readOnly={true} required={true} className="inputMaterial" defaultValue={fechaEmision}
                          type="date" />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Tipo de Moneda</Form.Label>
                        <Form.Select required={true} minLength="2" name="CodigoTipoMoneda" disabled={true} value={tipoMoneda} onChange={(event) => setTipoMoneda(event.target.value)}>
                          <option value="">Seleccione</option>
                          <option value="PEN">Soles (S/.)</option>
                          <option value="USD">Dólares ($)</option>
                        </Form.Select>
                        <input type="hidden" name='CodigoTipoMoneda' value={tipoMoneda}></input>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Importe Total</Form.Label>
                        <Form.Control
                          input="importeTotal"
                          onChange={validateFloatKeyPress}
                          step=".01" max={9999999999.99} name="ImporteTotal" placeholder={"Importe Total"} required={true} className="inputMaterial" value={importeTotal}
                          type="number" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                  <h6>Datos de Aprobación de O/C</h6>
                  <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Número de Orden de Compra</Form.Label>
                        <Form.Control
                          maxLength={10}
                          onChange={agregarTrim}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onKeyDown = {ConsultarAprobaciones}
                          name="NumeroOrdenCompra" autoComplete='off' placeholder={"Número de Orden de Compra"} required={true} className="inputMaterial" defaultValue={nroOrdenCompra}
                          type="text" />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Número de conformidad</Form.Label>
                        <Form.Select multiple={true} name="ListaConformidad" id="ListaConformidad" value={listaConformidades}>
                          {OpcionesConformidades.map((opcion) => (
                            <option key={opcion.numeroConformidad} data-importe={opcion.importe} value={opcion.numeroConformidad} onMouseDown={handleOptionMouseDown}>
                              {opcion.numeroConformidad}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>                            
                    </Col>
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Estado de Registro</Form.Label>
                        <Form.Select name="ListaEstado" onChange={(event) => setIdEstado(event.target.value)} value={idEstado}>
                          <option value="1">Pendiente</option>
                          <option value="2">Enviado</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Adjuntar Sustentos</Form.Label>
                        <Form.Control onChange={validarFormato} accept='.zip, .rar, .7z' name="UrlArchivoSustentos" required={true} className="inputMaterial"
                          type="file" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="text-center">
                      <br></br>
                      <Button type="submit" className='btn-lg' disabled={cargando} variant="primary">
                        <FontAwesomeIcon icon={faSave} /> Guardar
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="text-left">
                      <br></br>
                      <br></br>
                      En caso de consultas enviar un correo: <b>recepcion.facturas.crf@dinet.com.pe</b>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>      
    )
  }
}
export default Comprobante;